import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { ParaLarge } from "./elements/Section";
import PropTypes from "prop-types";
import LinkTo from "./elements/LinkTo";
import {
	grid,
	gridc,
	fluidPercentageRestricted,
	screen,
	container,
} from "../styles/mixins";
import color from "../styles/colors";
import { themeColors } from "../styles/colors";
import Image from "gatsby-image";
import FluidImagerX from "./elements/FluidImagerX";
import HeroHomeTextFlip from "./HeroHomeTextFlip";
import TrustpilotHeroUpdate from "./TrustpilotHeroUpdate";
import useHeroImagePosition from "../hooks/useHeroImagePosition";

const Section = styled.section`
	position: relative;
	background-color: ${(props) => themeColors[props.colorTheme].bg};
	color: ${(props) => themeColors[props.colorTheme].fg};
	padding-bottom: 0px !important;
	padding-top: 0px !important;

	@media ${screen("md")} {
		padding-top: 70px !important;
		padding-bottom: 70px !important;
	}
`;

const Heading = styled.h1`
	font-size: 55px;
	line-height: 1.2;
	font-weight: 600;
	letter-spacing: -0.03em;
	-webkit-font-smoothing: initial;
	-moz-osx-font-smoothing: initial;
	margin-bottom: 40px;

	@media ${screen("md")} {
		margin-bottom: 30px;
		font-weight: 500;
		font-size: 65px;
	}

	@media ${screen("lg")} {
		font-size: 70px;
	}
`;

const ContainerEl = styled.div`
	${container()};
`;

const ContentWrap = styled.div`
	${gridc(1, 48)}
	display: flex;
	flex-direction: column-reverse;
	overflow: hidden;

	@media ${screen("md")} {
		${gridc(3, 44)}
		display: block;
	}
`;

const HeroImageLink = styled(LinkTo)``;

const ImageWrapper = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 500px;

	@media ${screen("md")} {
		overflow: hidden;
		position: relative;
		height: 50vw;
		max-height: 630px;
		border-radius: 20px;
		${fluidPercentageRestricted("border-top-right-radius", 20, "designM")}
	}
`;

const HeroImage = styled(FluidImagerX)`
	height: 100%;
	display: none;

	@media ${screen("md")} {
		display: block;
	}
`;

const HeroImageMobile = styled(FluidImagerX)`
	height: 100%;

	@media ${screen("md")} {
		display: none;
	}
`;

const IntroWrapper = styled.div`
	z-index: 1;
	margin-top: 310px;
	background-color: ${color("purple")};
	padding-top: 50px;
	padding-bottom: 50px;
	${fluidPercentageRestricted("border-top-right-radius", 315, "designD")}
	${grid(48)}
	${gridc(3, 45)}

	@media ${screen("md")} {
		${grid(24)}
		border-radius: 0;
		margin-bottom: 50px;
		margin-top: 0;
		background-color: transparent;
		padding-top: 0;
		padding-bottom: 0;
	}
`;

const Paragraph = styled(ParaLarge)`
  font-size: 18px;
	margin-bottom: 50px !important;
	max-width: 290px;

  @media ${screen("md")} {
    font-size: 22px;
		margin-bottom: 0 !important;
		max-width: unset;
  }
`;

const IntroCol = styled.div`
	grid-column: 3/span 44;

	&:last-child {
		width: 100%;
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
	}

	@media ${screen("md")} {
		grid-column: span 12;

		&:last-child {
			align-items: flex-end;
			justify-content: flex-end;
		}
	}
`;

const Video = styled.video`
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	object-position: center;
	object-fit: cover;
`;

const HeroHome = ({
	title,
	text,
	image,
	mobileImage,
	imageLink,
	video,
	colorTheme = "light",
}) => {
	const imageWrapperRef = useRef(null);
	useHeroImagePosition(imageWrapperRef);

	return (
	<Section colorTheme={colorTheme}>
		<ContainerEl>
			<ContentWrap>
				<IntroWrapper>
					<IntroCol>
						<Heading as="h1">
							{title ? (
								title
							) : (
								<>
									Lending people{" "}
									<HeroHomeTextFlip colorTheme={colorTheme} />
								</>
							)}
						</Heading>
						<Paragraph as="h2">{text}</Paragraph>
					</IntroCol>
					<IntroCol>
						<TrustpilotHeroUpdate colorTheme="dark" />
					</IntroCol>
				</IntroWrapper>

				{imageLink?.url ? (
					<HeroImageLink
						url={imageLink.url}
					>
						{video?.[0] && image?.[0] && (
							<ImageWrapper ref={imageWrapperRef}>
								<Video autoPlay autoplay muted poster={image?.[0].url}>
									<source src={video?.[0].url} type="video/mp4" />
								</Video>
							</ImageWrapper>
						)}
					</HeroImageLink>
				) : (
					video?.[0] && image?.[0] && (
						<ImageWrapper ref={imageWrapperRef}>
							<Video autoPlay autoplay muted poster={image?.[0].url}>
								<source src={video?.[0].url} type="video/mp4" />
							</Video>
						</ImageWrapper>
					)
				)}

				{imageLink?.url ? (
					<HeroImageLink
						url={imageLink.url}
					>
						{(mobileImage?.[0] || image?.[0] && !video?.[0]) && (
							<ImageWrapper ref={imageWrapperRef}>
								<HeroImage
									image={image?.[0]}
									sizes="(min-width: 720px) 50vw, 100vw"
									loading="eager"
									skipBlur={true}
									gatsbyImage={false}
									alt={image?.[0]?.title || null}
								/>
								<HeroImageMobile
									image={mobileImage?.[0]}
									sizes="(min-width: 720px) 50vw, 100vw"
									loading="eager"
									skipBlur={true}
									gatsbyImage={false}
									alt={mobileImage?.[0]?.title || null}
								/>
							</ImageWrapper>
						)}
					</HeroImageLink>
				) : (
					(mobileImage?.[0] || image?.[0] && !video?.[0]) && (
						<ImageWrapper ref={imageWrapperRef}>
							<HeroImage
								image={image?.[0]}
								sizes="(min-width: 720px) 50vw, 100vw"
								loading="eager"
								skipBlur={true}
								gatsbyImage={false}
								alt={image?.[0]?.title || null}
							/>
							<HeroImageMobile
								image={mobileImage?.[0]}
								sizes="(min-width: 720px) 50vw, 100vw"
								loading="eager"
								skipBlur={true}
								gatsbyImage={false}
								alt={mobileImage?.[0]?.title || null}
							/>
						</ImageWrapper>
					)
				)}
			</ContentWrap>
		</ContainerEl>
	</Section>
)};

HeroHome.propTypes = {
	colorTheme: PropTypes.string,
};

HeroHome.defaultProps = {
	colorTheme: "light",
};

export default HeroHome;
