import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "@emotion/styled";
import { MetaSmall } from "./elements/Section";
import PropTypes from "prop-types";
import { screen } from "../styles/mixins";
import color, { themeColors } from "../styles/colors";
import trustpilotStars from "../styles/trustpilotStars";
import { ReactComponent as TrustpilotLogo } from "../images/vectors/trustpilot-logo.svg";
import { ReactComponent as TrustpilotLogoLight } from "../images/vectors/trustpilot-logo-light.svg";

const Trustpilot = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	color: ${(props) => props.mobileTheme === "light" ? color("white") : themeColors[props.colorTheme].fg};

	svg {
		width: auto;
	}

	@media ${screen("md")} {
		margin: 0;
		color: ${(props) => themeColors[props.colorTheme].fg};
	}
`;

const TrustpilotImages = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: 0.4em;
`;

const TrustpilotLogoWrapper = styled.div`
	display: ${(props) => (props.mobile || props.mobileTheme === "dark" ? "block" : "none")};
	margin-right: 1em;

	svg {
		height: 1.5em;
		padding-bottom: 0.2em;
	}

	@media ${screen("md")} {
		display: ${(props) => (props.desktop ? "block" : "none")};
	}
`;

const TrustpilotStarsWrapper = styled.div`
	svg {
		width: 109px;
	}
`;

const TrustpilotText = styled(MetaSmall)`
  font-size: 14px;
  margin-top: 10px;
  display: block;

	a {
		border-bottom: 1px solid;
		transition: all 0.2s;

		&:hover {
			opacity: 0.8;
		}
	}
`;

const TrustpilotHero = ({ className, mobileTheme = "light", colorTheme = "light" }) => {
	const trustpilotData = useStaticQuery(graphql`
		query TrustpilotHeroUpdateQuery {
			allTrustPilotPublicBusinessUnit {
				nodes {
					numberOfReviews {
						total
					}
					score {
						stars
						trustScore
					}
				}
			}
		}
	`);

	const businessUnitData =
		trustpilotData.allTrustPilotPublicBusinessUnit.nodes[0];

	const { el: TrustpilotStars, text: trustpilotTrustText } = trustpilotStars(
		businessUnitData?.score.stars
	);

	return (
		<Trustpilot className={className} colorTheme={colorTheme} mobileTheme={mobileTheme}>
			<TrustpilotImages>
				{mobileTheme === "light" && (
					<TrustpilotLogoWrapper mobile>
						<TrustpilotLogoLight />
					</TrustpilotLogoWrapper>
				)}
				<TrustpilotLogoWrapper desktop mobileTheme={mobileTheme}>
					<a
						href="https://www.trustpilot.com/review/thelendingpeople.co.nz"
						target="_blank">
						<TrustpilotLogo />
					</a>
				</TrustpilotLogoWrapper>
				<TrustpilotStarsWrapper>{TrustpilotStars}</TrustpilotStarsWrapper>
			</TrustpilotImages>
			<TrustpilotText>
				{trustpilotTrustText} - Based on{" "}
				<a
					href="https://www.trustpilot.com/review/thelendingpeople.co.nz"
					target="_blank">
					{businessUnitData.numberOfReviews.total} reviews
				</a>
			</TrustpilotText>
		</Trustpilot>
	);
};

TrustpilotHero.propTypes = {
	colorTheme: PropTypes.string,
};

TrustpilotHero.defaultProps = {
	colorTheme: "light",
};

export default TrustpilotHero;
